import React from 'react'
import { login, isAuthenticated } from '../utils/auth'
import { navigate } from 'gatsby'
import Welcome from '../components/Welcome'

const HomePage = () => {
  if (!isAuthenticated()) {
    login()
    return <Welcome text="Redirecting to login..." />
  }

  navigate('/studies')

  return null
}

export default HomePage
